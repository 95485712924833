import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import CustomDropdown from '../Components/CustomDropdown';
import { categoryTypeQuestion } from 'constants/questionTypes';


const Categories = (props) => {

  const { saveUpdatedAnswers,
    currentQuestionDet,
    question,
    description,
    categoryQues,
    language } = props


  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryQues, setSelectedCategoryQues] = useState(false);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState([]);

  useEffect(() => {
    if (categoryQues) {
      const CatQues = categoryQues.filter(cq =>
        cq.id === currentQuestionDet.category_question_id)?.[0]

      setSelectedCategoryQues(CatQues)
      if (CatQues?.answers) {
        setSelectedCategoryOptions(CatQues?.answers)
      } else {
      setSelectedCategoryOptions(false)
    }
    } 
  }, [categoryQues, currentQuestionDet])

  useEffect(() => {
    if (currentQuestionDet?.response && selectedCategoryQues?.answers) {
      setSelectedCategory(selectedCategoryQues?.answers.filter(op => op.id === currentQuestionDet?.response.response)?.[0])
    } else {
      setSelectedCategory('')
    }
  },[currentQuestionDet, selectedCategoryQues])

  function SaveResponse(response) {
    const Response = {
      ques_sort_order: currentQuestionDet?.id,
      id: selectedCategoryQues.id,
      response: response?.id, 
      subfactor: selectedCategoryQues?.subfactor,
      priority: selectedCategoryQues?.priority,
      role: selectedCategoryQues?.role,
      reverse: selectedCategoryQues?.reverse,
      clicked: true,
    }
    saveUpdatedAnswers(currentQuestionDet?.id, Response, categoryTypeQuestion)
    setSelectedCategory(selectedCategoryQues?.answers.find(op => op.id === Response.response))
  }


  return (
    <CategoriesContainer>
      <Title>Categorical Question</Title>
      <QuestionText>{selectedCategoryQues?.name || question}</QuestionText>
      <Description questionLength={selectedCategoryQues?.question?.length} > {(language === 'FR'? selectedCategoryQues?.fr : selectedCategoryQues?.question) || description}</Description>
      {selectedCategoryOptions && <CustomDropdown
        id={`sel_${currentQuestionDet?.id}`}
        listId={`lists_${currentQuestionDet?.id}`}
        value={selectedCategory}
        onSelect={SaveResponse}
        options={selectedCategoryOptions} />}

    </CategoriesContainer>
  );
};

export default Categories;

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:calc(100%);
  max-width:600px;
`;

const QuestionText = styled.h2`
  margin-bottom: 1rem;
  color:#2A3039;
  font-size: 20px;
  font-weight: 600;
`;

const Dropdown = styled.select`
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Title = styled.div`
color: #2D70E2;
text-transform: uppercase;
font-size:14px;
font-weight:600;
`

const Description = styled.div`
color: #2A3039;
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height:1.6;
margin-bottom:26px;

@media(max-height:700px){
  font-size:${props => props.questionLength > 200 ? '14px' : '16px'};
}

@media(max-height:550px){
  font-size:${props => props.questionLength > 200 ? '12px' : '16px'};
}
`
