
export const questions = [
    {
        id: 1,
        type: 'introSection',
        emoji:'🧭',
        title: "Innerlogic's readiness & ROI calculator",
        subtitle:"Ready to go from insights to action instantly with innerlogic’s AI culture copilot?",
        text:['Powered by AI and a world-class knowledge base, innerlogic instantly transforms culture KPI’s and feedback into rich recommendations unique to your organization, teams, and leaders.'],
        buttonType:'Begin',
        intro_id:1,
        total:1,
        buttonsVisible: true
      },
      {
        id: 2,
        question: 'How much of a priority is it for your organization to scale a healthy, high-performing culture?',
        "type": "question",
        "choices": [
          { "id": '7a', "text": 'Very low' },
          { "id": '7b', "text": 'Very high' }
        ],
        buttonsVisible: true
      },
      {
        id: 3,
        question: 'How much do you value the unique experiences and perspectives of your people when it comes to building / growing your culture?',
        "type": "question",
        "choices": [
          { "id": '7a', "text": 'Not at all' },
          { "id": '7b', "text": 'Very much' }
        ],
        buttonsVisible: true
      },
      {
        id: 4,
        "text": "What is your current process for gathering data on the unique experiences and perspectives within your culture? (select all that apply)",
        "type": "multiSelect",
        "choices": [
          { "id": '2a', "text": 'No formal process',value:10 },
          { "id": '2b', "text": 'Informal conversations',value:10 },
          { "id": '2c', "text": 'Structured Interviews/check-ins',value:10 },
          { "id": '2d', "text": 'Static surveys or pulses (standardized questions for everyone)',value:10 },
          { "id": '2e', "text": 'Conversational AI (dynamic questions/prompts unique to each individual)',value:0 },
        ],
        buttonsVisible: true
      },
      {
        id: 5,
        question: 'How important is it for your organization to enact timely change regarding culture gaps or growth areas?',
        "type": "question",
        "choices": [
          { "id": '7a', "text": 'Not at all' },
          { "id": '7b', "text": 'Very much' }
        ],
        buttonsVisible: true
      },
      {
        id: 6,
        "text": "How long does it typically take for your organization to implement effective, evidence-based changes using culture feedback?",
        "type": "multipleChoice",
        "choices": [
          { "id": '5b', "text": "About a week" },
          { "id": '5c', "text": "About a month" },
          { "id": '5d', "text": "2-3 months" },
          { "id": '5e', "text": "More than 3 months" },
          { "id": '5f', "text": "Never happens" }
        ],
        buttonsVisible: true
      },
      {
        id: 7,
        question: 'How valuable would it be for yourself and other leaders in your organization to have instant, ongoing access to culture resources and solutions matched to you based on your culture gaps/growth areas?',
        "type": "question",
        "choices": [
          { "id": '7a', "text": 'Not at all' },
          { "id": '7b', "text": 'Very valuable' }
        ],
        buttonsVisible: true
      },
      {
        id: 8,
        "text": "How much access do you and other leaders in your organization currently have to instant, ongoing access to culture resources and solutions based on your culture gaps/growth areas?",
        "type": "multipleChoice",
        "choices": [
          { "id": '8a', "text": "None" },
          { "id": '8b', "text": "A few times a year" },
          { "id": '8c', "text": "About half the year" },
          { "id": '8d', "text": "Most of the time" },
          { "id": '8e', "text": "Unlimited / full time access" }
        ],
        buttonsVisible: true
      },
      {
        id: 9,
        type: 'completed',
        buttonsVisible: true
      },
      {
        id: 10,
        type: 'submit',
        buttonsVisible: false
      },
    // Add slider, multipleChoice, and feedback questions as needed
  ];
