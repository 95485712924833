import React, { useState,useRef,useEffect } from 'react';
import styled from 'styled-components';




const Submit = () =>{


    return (

        <FeedbackContainer>
            <Emoji><span role="img" aria-label="submit clap">👏</span> </Emoji>
            <QuestionText>Congratulations on completing every question in this survey!</QuestionText>
            <Description>
            Your valuable insights matter to us. Go ahead and hit that <Bold>Submit</Bold> button to share your thoughts and help share our organization’s future. 
            </Description>
        </FeedbackContainer>

    )
}


export default Submit;


const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width:600px;
  font-family: 'Raleway', sans-serif;
`;

const QuestionText = styled.h2`
  margin-bottom: 1rem;
  color:#2A3039;
  font-size: 20px;
  font-weight: 600;
`;

const Description = styled.div`
color: #2A3039;
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom:26px;
line-height: 150%;
`
const Emoji = styled.div`
    font-size: 48px;
    margin-bottom:24px;
`

const Bold = styled.span`
    font-weight:600;
    color:#2D70E2;
`
