export const ALL_ERRORS = "ALL_ERRORS";
export const CLEAR_ALL ='CLEAR_ALL';
export const VALIDATION_ERRORS = 'VALIDATION_ERRORS'
export const AUTH_ERRORS = 'AUTH_ERRORS'
export const CLEAR_ERRORS ='CLEAR_ERRORS';
export const SG_CLEAR_ERRORS ='SG_CLEAR_ERRORS';
export const SG_CLEAR_ALL ='SG_CLEAR_ALL';
export const SET_ERROR_STATE = "SET_ERROR_STATE"
//
export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
// 
// 
export const PENDING_ANSWERS = "PENDING_ANSWERS"
export const SET_ANSWERS = "SET_ANSWERS"
export const GET_ANSWERS = "GET_ANSWERS";
export const LOAD_ANSWERS = "LOAD_ANSWERS"; //saga use this
export const DELETE_ANSWER = "DELETE_ANSWER";
export const PULL_ANSWER = "PULL_ANSWER"; //saga use this
export const FETCH_ANSWER = "FETCH_ANSWER";
export const FETCH_CULTURE_ANSWER = "FETCH_CULTURE_ANSWER";
export const FETCH_MOOD_ANSWER = "FETCH_MOOD_ANSWER";
export const SELECTED_LEADERSHIP = "SELECTED_LEADERSHIP";
export const RESET_LEADERSHIP = 'RESET_LEADERSHIP';
export const FETCH_LEADERSHIP_ANSWER = "FETCH_LEADERSHIP_ANSWER";

//
export const UPDATE_ANSWER = 'UPDATE_ANSWER'; //saga use this
export const EDIT_ANSWER = 'EDIT_ANSWER';
export const ADD_ANSWER = 'ADD_ANSWER';
export const ADD_CULTURE_ANSWER = 'ADD_CULTURE_ANSWER';
export const ADD_MOOD_ANSWER = 'ADD_MOOD_ANSWER';
export const ADD_TEAM_ANSWER = 'ADD_TEAM_ANSWER';
export const MOOD_UPDATE_COMMENTS = 'MOOD_UPDATE_COMMENTS';
export const ADD_LEADERSHIP_ANSWER = 'ADD_LEADERSHIP_ANSWER';
//
export const ADD_PERSONALITY_ANSWER = 'ADD_PERSONALITY_ANSWER';
export const FETCH_PERSONALITY_ANSWER = "FETCH_PERSONALITY_ANSWER";
export const GET_PERSONALITY_ANSWER = "GET_PERSONALITY_ANSWER";
export const SG_GET_PERSONALITY_ANSWER = "SG_GET_PERSONALITY_ANSWER";
export const UPDATE_PERSONALITY_ANSWER = "UPDATE_PERSONALITY_ANSWER";
export const SG_UPDATE_PERSONALITY_ANSWER = "SG_UPDATE_PERSONALITY_ANSWER";
export const START_PERSONALITY_ANSWER = "START_PERSONALITY_ANSWER";
export const SG_START_PERSONALITY_ANSWER = "SG_START_PERSONALITY_ANSWER";
//
export const SAVE_ANSWER = 'SAVE_ANSWER'; //saga use this
export const POST_ANSWER = 'POST_ANSWER';
export const SUBMIT_ANSWER = 'SUBMIT_ANSWER'; //saga use this
export const CLEAR_ANSWER = 'CLEAR_ANSWER';
// 
export const PENDING_QUESTIONS = "PENDING_QUESTIONS";
//
export const PENDING_CULTURE_QUESTIONS = "PENDING_CULTURE_QUESTIONS";
export const PENDING_MOOD_QUESTIONS = "PENDING_MOOD_QUESTIONS";
export const PENDING_LEADERSHIP_QUESTIONS = "PENDING_LEADERSHIP_QUESTIONS";
export const PENDING_PERSONALITY_QUESTIONS = "PENDING_PERSONALITY_QUESTIONS";
//
export const GET_CULTURE_QUESTIONS = "GET_CULTURE_QUESTIONS";
export const GET_MOOD_QUESTIONS = "GET_MOOD_QUESTIONS";
export const GET_LEADERSHIP_QUESTIONS = "GET_LEADERSHIP_QUESTIONS";
export const GET_PERSONALITY_QUESTIONS = "GET_PERSONALITY_QUESTIONS";
export const GET_PREVIOUS_RESPONSE = "GET_PREVIOUS_RESPONSE"; 
export const PREVIOUS_RESPONSE_PENDING = "PREVIOUS_RESPONSE_PENDING"; 
export const LOAD_CULTURE_QUESTIONS = "LOAD_CULTURE_QUESTIONS"; //saga use this
export const LOAD_MOOD_QUESTIONS = "LOAD_MOOD_QUESTIONS"; //saga use this
export const LOAD_LEADERSHIP_QUESTIONS = "LOAD_LEADERSHIP_QUESTIONS"; //saga use this
export const LOAD_PERSONALITY_QUESTIONS = "LOAD_PERSONALITY_QUESTIONS"; //saga use this
export const LOAD_PREVIOUS_RESPONSE = "LOAD_PREVIOUS_RESPONSE"; //saga use this
export const DELETE_QUESTIONS = "DELETE_QUESTIONS";
//
export const ADD_QUESTIONS = "ADD_QUESTIONS";
export const FETCH_QUESTIONS = "FETCH_QUESTIONS"; //saga use this
export const UPDATE_QUESTIONSS = 'UPDATE_QUESTIONSS'; //saga use this
export const EDIT_QUESTIONS = 'EDIT_QUESTIONS'
export const CLEAR_QUESTIONS = 'CLEAR_QUESTIONS';
//
export const PENDING_ALL_MODULAR_QUESTIONS = 'PENDING_ALL_MODULAR_QUESTIONS';
export const GET_ALL_MODULAR_QUESTIONS = 'GET_ALL_MODULAR_QUESTIONS'; 
export const GET_MODULAR_QUESTIONS = "GET_MODULAR_QUESTIONS"
export const SG_GET_ALL_MODULAR_QUESTIONS = 'SG_GET_ALL_MODULAR_QUESTIONS'; //saga use this
//
export const LOAD_EMOTION_DEFINITIONS = 'LOAD_EMOTION_DEFINITIONS'; //saga use this
export const GET_EMOTION_DEFINITIONS = 'GET_EMOTION_DEFINITIONS'
//
export const LOAD_EMOTIONS = 'LOAD_EMOTIONS'; //saga use this
export const GET_EMOTIONS = 'GET_EMOTIONS'
//
export const LOAD_MOODS = 'LOAD_MOODS'; //saga use this
export const GET_MOODS = 'GET_MOODS'
//
export const LOAD_PERSONALITY = 'LOAD_PERSONALITY'; //saga use this
export const GET_PERSONALITY = 'GET_PERSONALITY'
//
export const LOAD_TRAIT_DATA = 'LOAD_TRAIT_DATA'; //saga use this
export const GET_TRAIT_DATA = 'GET_TRAIT_DATA'
//
export const LOAD_CULTURE_DATA = 'LOAD_CULTURE_DATA'; //saga use this
export const GET_CULTURE_DATA = 'GET_CULTURE_DATA'

export const SG_GET_CULTURE_DATA = 'SG_GET_CULTURE_DATA'
//
export const PENDING_SURVEY = "PENDING_SURVEY";
//
export const START_SURVEY = 'START_SURVEY'; //saga use this
export const SG_START_SURVEY = 'SG_START_SURVEY'
//
export const START_BRIEF_SURVEY = 'START_BRIEF_SURVEY'; 
export const SG_START_BRIEF_SURVEY = 'SG_START_BRIEF_SURVEY'; //saga use this
// sends updated answers to server
// export const UPDATE_BRIEF_SURVEY_ANSWERS = 'UPDATE_BRIEF_SURVEY_ANSWERS'; 
// export const SG_UPDATE_BRIEF_SURVEY_ANSWERS = 'SG_UPDATE_BRIEF_SURVEY_ANSWERS'; //saga use this
// 
export const NEW_VALIDATION_TOKEN = 'NEW_VALIDATION_TOKEN'
export const RESET_VALIDATION_TOKEN = "RESET_VALIDATION_TOKEN";
//
// export const CREATE_BRIEF_SURVEY = 'CREATE_BRIEF_SURVEY'; 
// export const SG_CREATE_BRIEF_SURVEY = 'SG_CREATE_SURVEY'; //saga use this
//
export const POST_BRIEF_RESPONSE = 'POST_BRIEF_RESPONSE';
// sends updated answers to server
export const UPDATE_STORED_RESPONSE = "UPDATE_STORED_RESPONSE";

export const UPDATE_BRIEF_RESPONSE = 'UPDATE_BRIEF_RESPONSE'; 
export const SG_UPDATE_BRIEF_RESPONSE = 'SG_UPDATE_BRIEF_RESPONSE';
// brings asnwers data over api
export const UPDATE_BRIEF_RESPONSE_ANSWERS = 'UPDATE_BRIEF_RESPONSE_ANSWERS'; 
export const SG_UPDATE_BRIEF_RESPONSE_ANSWERS = 'SG_UPDATE_BRIEF_RESPONSE_ANSWERS'; 
// submit the final response
export const SG_POST_BRIEF_RESPONSE = 'SG_POST_BRIEF_RESPONSE'; //saga use this

//
export const GET_CHAT_GPT_SUMMARY = 'GET_CHAT_GPT_SUMMARY';
export const CLEAR_CHAT_GPT_SUMMARY = 'CLEAR_CHAT_GPT_SUMMARY'; 
export const PENDING_CHAT_GPT_SUMMARY = 'PENDING_CHAT_GPT_SUMMARY'; 
export const SG_GET_CHAT_GPT_SUMMARY = 'SG_GET_CHAT_GPT_SUMMARY'; //saga use this
export const SG_GET_CHAT_GPT_SUMMARY_MULTI = 'SG_GET_CHAT_GPT_SUMMARY_MULTI'; //saga use this
//
export const PENDING_MARKETING_ROI = "PENDING_MARKETING_ROI";
export const CLEAR_MARKETING_ROI = 'CLEAR_MARKETING_ROI'; 
export const MARKETING_ROI = 'MARKETING_ROI';
export const SG_MARKETING_ROI = 'SG_MARKETING_ROI';
//