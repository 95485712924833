import React from "react";
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import CustomButton from './Button'

const SkipModal = ({open,setOpen, SkipQuestion}) =>{
  
    return (
      <Modal
      dimmer={'blurring'}
      open={open}
      onClose={() => setOpen(false)}>
        <Modal.Header>Your Feedback Matters! <span role="img" aria-label="star">🌟</span></Modal.Header>
          <ModalContent>
          We understand that your time is precious, but we'd like to take
           a moment to emphasize how important your feedback is to us.
           Your thoughts and insights can make a world of difference! 
           <span role="img" aria-label="world">🌍</span> By sharing your experiences, you help us:
           <ul>
              <li style={{marginBottom:15}}><strong>Drive meaningful change <span role="img" aria-label="drive">🛠️</span> </strong> - Your feedback pinpoints areas for 
              improvement, enabling us to prioritize and implement impactful changes.</li>
            <li style={{marginBottom:15}}>
            <strong>Reinforce our strengths <span role="img" aria-label="strength">💪</span></strong> - Your positive feedback helps us maintain 
            and enhance the features that resonate with you, ensuring exceptional services.
            </li>
            <li>
              <strong>Influence strategic decisions <span role="img" aria-label="compass">🧭</span></strong> - Your input informs our 
              decision-making process, leading to a more responsive and user-focused organization.
            </li>
           </ul>
           
          </ModalContent>
          <Modal.Actions>
           
          
            <CustomButton type='skip' onClick={SkipQuestion}>
              Skip anyway
            </CustomButton>

            <CustomButton onClick={() =>setOpen(false)} type='leave'>
              Leave feedback
            </CustomButton>
          </Modal.Actions>
      </Modal>
  
    )
  }

export default SkipModal


const ModalContent = styled.div`
  padding:20px;
  font-size:16px;
  line-height:1.6;
  font-family:Poppins, sans-serif;

  @media (max-width:768px){
    font-size:14px;
  }
`