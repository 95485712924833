import React from 'react';
import styled from 'styled-components';
import Slider from '../Components/Slider';

const Question = ({ question, saveUpdatedAnswers, currentQuestionDet, language,qualifier }) => {

  const get_qualifier = () =>{

    if(question?.qualifier){
      return question?.qualifier
    }

    if(qualifier){
      return qualifier
    }
    if(language === 'FR'){
      return 'Dans cette culture...'
    }

      return 'In this organization...'
  }


return (<QuestionContainer>
    <Title>{question?.title? question.title : "DEI Question"}</Title>
    <Qualifier>{get_qualifier()}</Qualifier>
    <QuestionText>{language === 'FR'? question?.fr : question?.question}</QuestionText>
    <Slider 
      id={`slider-track-${currentQuestionDet?.id}`} 
      question={question} 
      saveUpdatedAnswers={saveUpdatedAnswers} 
      currentQuestionDet={currentQuestionDet} />
  </QuestionContainer>)
};
export default Question;

const QuestionContainer = styled.div`
  width: 100%;
  max-width:600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  color: #2D70E2;
  text-transform: uppercase;
  font-size:14px;
  font-weight:600;
  margin-bottom: 40px;
`

const Qualifier = styled.div`
  color:#2A3039;
  font-size:20px;
  font-weight:bold;
  margin-bottom:14px;
  line-height:1.4;
`

const QuestionText = styled.p`
  font-size:16px;
  font-weight:400;
  color: #2A3039;
`;

