import React from 'react';
import { Navigate } from 'react-router-dom';

import Health from "health";

import Survey from "survey/TeamCulture/index"

import CultureAudit from 'survey/Audit/index'

import PersonalitySurvey from "survey/Personality/index"
import TeamIntelligence from "survey/TeamCulture/TeamIntelligence/index"

import EdiSurvey from 'survey/EDI';
import EdiPrivacy from 'survey/EDI/Privacy';

import Template from 'survey/Template/index';

import Blink from 'survey/Blink/index'
// import Debrief from 'survey/Debriefs/index'
// import Demo from "survey/Audit/demo"

import Subfactor from 'survey/Test/subfactor_value'
import Tested from 'survey/AuditV2/Survey'
import Context from 'survey/Context'
// import Legacy from 'survey/Test/legacy'

// New Survey Design May 2023
import CultureAuditV2 from 'survey/AuditV2/index'
import CultureAuditV2a from 'survey/AuditV2/SurveyV2'
import Test from 'survey/Test'
import InitialReport from 'survey/Context/Initial_Report'

export const routes = [

  {
    path: "/survey/:token", 
    element: <Survey />,
  },
  {
    path: "/personality-survey/:token",
    element: <PersonalitySurvey />,
  },
  {
    path: "/team-intelligence/:token",
    element: <TeamIntelligence />,
  },
  {
    path: "/test",
    element: <Tested />,
  },
  {
    path: "/roi-calculator",
    element: <Context />,
  },
  {
    path: "/context",
    element: <Context />,
  },
  {
    path: "/report",
    element: <InitialReport />,
  },
    {
    path: "/culture-audit/:token",
    element: <CultureAudit />,
  },
  {
    path: "/ediV2/:token",
    element: <CultureAuditV2 />,
  },
  {
    path: "/culture-audit-v2a/:token",
    element: <CultureAuditV2a />,
  },
  {
    path: "/edi-survey/:token",
    element: <EdiSurvey />,
  },
   {
    path: "/edi-survey/privacy",
    element: <EdiPrivacy />,
  },
  {
    path: "/insight/:token",
    element: <Template />,
  },
  {
    path: '/new',
    children: [
      {
        path: "blink",
        element: <Blink />,
      },
      { path: 'test/animate', element: <Test /> },
      { path: 'test/sub-factor', element: <Subfactor /> },
      // { path: 'legacy', element: <Legacy /> },
      // {
      //   path: "debrief",
      //   element: <Debrief />,
      // },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  { path: '/', element: <Survey /> },
  {
    path: "health", element: <Health />,},
  { path: '*', element: <Navigate to="/404" /> }
];

export default routes;
