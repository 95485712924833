import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import format from "date-fns/format";
const FR1 = "Nous ne pouvons pas obtenir d'informations sur cette enquête"
const FR2 = "Cette enquête a été complétée"
const FR3 = "Cette enquête a été fermée et n'est plus accessible"
const FR4 = "Hmm! Il semble que cette enquête a expiré"


const Completed = ({ status, language, statusText, completedOn, surveyStatus,title }) => {
  const [endDate, setEndDate] = useState(false)

  let CustomMessage = statusText ? statusText : 'We are unable to get information about this survey'

  useEffect(() => {
    if (completedOn) {
      setEndDate(format(new Date(completedOn), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))
    }
  }, [completedOn])

  if (language === 'FR') {
    CustomMessage = FR1
  }

  if (status === 3) {
    CustomMessage = 'This survey has been completed'
    if (language === 1) {
      CustomMessage = FR2
    }
  }
  if (status > 4 && status < 11) {
    CustomMessage = 'This survey has been closed and is no longer accessible'
    if (language === 1) {
      CustomMessage = FR3
    }
  }
  if (status === 100) {
    CustomMessage = 'Hmm! It looks like this survey has expired'
    if (language === 1) {
      CustomMessage = FR4
    }
  }

console.log(title)
  return (
    <FeedbackContainer>
      <Emoji> <span role="img" aria-label="completed">🎉</span> </Emoji>
      <QuestionText>{language === 'FR' ? 'Indice de Culture' : title? title:'Culture Index'}</QuestionText>
      <Description>
        {statusText ? statusText : CustomMessage}
        {endDate ? ` on ${format(
          new Date(endDate.toLocaleString()),
          "EEEE, MMM do h:mm a"
        )}.` : '.'}


      </Description>
    </FeedbackContainer>
  )
}


export default Completed;


const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:100%;
  max-width:600px;
  font-family: 'Raleway', sans-serif;
`;

const QuestionText = styled.h2`
  margin-bottom: 1rem;
  color:#2A3039;
  font-size: 20px;
  font-weight: 600;
`;

const Description = styled.div`
color: #2A3039;
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom:26px;
line-height: 150%;
`
const Emoji = styled.div`
    font-size: 48px;
    margin-bottom:24px;
`
