import { combineReducers } from "redux";

import errors from "./errors";
import questions from "./questions";
import answers from "./answers";
import briefresponse from "./briefresponse"
import chatgpt from "./chatgpt";
import marketingRoi from "./marketing";


export default combineReducers({
  errors,
  answers,
  questions,
  briefresponse,
  chatgpt,
  marketingRoi,
});
