import React from 'react';
import styled from 'styled-components';

const ProgressBar = ({ currentQuestion, totalQuestions, quesId }) => {
  const progressPercentage = (currentQuestion / totalQuestions) * 100;
  const isLocalhost = window.location.hostname === 'localhost';

  return (
    <ProgressBarContainer>
      <ProgressText>
        <span style={{ color: '#2A3039', fontWeight: 600 }}>{currentQuestion}</span> of {totalQuestions}
        {isLocalhost && <>ID# ({quesId} for debugging) </>}
      </ProgressText>
      <FilledBar style={{ width: `${progressPercentage}%` }} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;

const ProgressBarContainer = styled.div`
  width: calc(100vw - 40px);
  margin-left:20px;
  margin-top:50px;
  height: 6px;
  background-color: #D5E2F9;
  position: fixed;
  border-radius: 20px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
`;

const FilledBar = styled.div`
  height: 100%;
  background-color: #2D70E2;
  transition: width 300ms;
  border-radius: 20px;
`;

const ProgressText = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #666D79;
  top:-30px;
  font-size: 14px;
  font-weight: 400;
`;