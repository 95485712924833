// ... other imports ...

import styled from 'styled-components';
import React,{ useState } from 'react';
import Pic from 'assets/images/lock.png'



const Screen2 = () =>{

    return (
        <Container>
             <Emoji src={Pic}/>
             <Text>
            Do you agree with your personal data being processed as described below and in our privacy statement?
            </Text>

            <Linked href="" target="_blank">Privacy agreement</Linked>
        </Container>
       
    )
}

export default Screen2

const Text = styled.div`
font-weight: 500;
font-size: 24px;
line-height: 1.6;
color: #2A3039;
margin-bottom:24px;
`

const Container = styled.div`
    font-family: 'Raleway', sans-serif;
`

const Linked = styled.a`
    text-decoration: underline;
    font-weight: 500;
    font-size: 16px;
    line-height: 128%;
    color: #2D70E2;
`

const Emoji = styled.img`
    height:40px;
    margin-bottom: 40px;
`