// ... other imports ...

import styled from 'styled-components';
import React,{ useState } from 'react';
import Pic from 'assets/images/survey.png'



const Screen2 = () =>{

    return (
        <Text>
        Your <Highlight>responses</Highlight>
        from this survey
        <Image src={Pic} alt="Image description" />
        will be used to create <Bold>real change.</Bold>

        </Text>
    )
}

export default Screen2

const Text = styled.div`
font-weight: 500;
font-size: 44px;
line-height: 1.4;
color: #2A3039;
width:100%;

@media (max-width: 768px) {
    font-size: 38px;
}
`

const Image = styled.img`
    width:162px;
    display: inline;
    vertical-align: middle;
    margin-left: 0.25em;
    margin-right: 0.25em;
    border-radius: 30px;
`

const InlineWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Highlight = styled.span`
background: #EBF1FD;
border-radius: 40px;
padding:0px 16px;
margin:0px 15px 0px 0px;

`

const Bold = styled.span`
    font-weight:600;
    color:#2D70E2;
`
