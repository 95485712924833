import React from "react";
import styled from "styled-components";
import SliderOutcome from "../Components/SliderOutcome";

const QuestionOutcome = ({
  question,
  saveUpdatedAnswers,
  currentQuestionDet,
  resetResponsesOnChange,
  questionSequenceMap,
  language,
}) => {
  return (
    <QuestionContainer>
      <Title>
        {currentQuestionDet.name}
      </Title>
      {/* <Qualifier>In this culture...</Qualifier>  */}
      <QuestionText>{question?.question}</QuestionText>
      <SliderOutcome
        id={`slider-track-${currentQuestionDet?.id}`}
        resetResponsesOnChange={resetResponsesOnChange}
        question={question}
        saveUpdatedAnswers={saveUpdatedAnswers}
        currentQuestionDet={currentQuestionDet}
        questionSequenceMap={questionSequenceMap}
      />
    </QuestionContainer>
  );
};
export default QuestionOutcome;

const QuestionContainer = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  color: #2d70e2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 40px;
`;

const Qualifier = styled.div`
  color: #2a3039;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 14px;
`;

const QuestionText = styled.p`
  font-size: 16px;
  color: #2a3039;
`;
