import React, { useState} from 'react';
import styled from 'styled-components';
import Pic from 'assets/images/gb.png'
import FR from 'assets/images/fr.png'

const Language = ({language,setLanguage}) =>{
    // const [language,setLanguage] = useState('EN')

    const changeLanguage = () =>{
        if(language === 'EN'){
            setLanguage('FR')
        }else{
            setLanguage('EN')
        }
    }

    return (
        <Container onClick={()=>changeLanguage()}>
            <Image src={language === 'EN'?Pic:FR} alt="Image description" />
            <Text>{language}</Text>
        </Container>
    )
}


export default Language


const Container = styled.div`
    display:flex;
    align-items:center;
    position:absolute;
    top:15px;
    right:30px;
    cursor:pointer;
    z-index:2;
`

const Image = styled.img`
    width:24px;
    margin-right:8px;
`

const Text = styled.div`
font-weight: 600;
font-size: 16px;
color: #2A3039;
`
