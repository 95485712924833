import axios from "axios";
import urlconfig from "../_config";
const VERSION = "v1";

export const GET_START_SURVEY = (payload) =>
  axios.get(`${urlconfig.surveyapi}/${VERSION}/start-survey/${payload ? payload : ''}`);

export const GET_CULTURE_QUESTIONS = (payload) =>
  axios.get(`${urlconfig.surveyapi}/${VERSION}/culture-questions/${payload ? payload : ''}`);

export const GET_MOOD_QUESTIONS = (payload) =>
  axios.get(`${urlconfig.surveyapi}/${VERSION}/mood-questions/${payload ? payload : ''}`);

export const GET_LEADERSHIP_QUESTIONS = (payload) =>
  axios.get(`${urlconfig.surveyapi}/${VERSION}/leadership-questions/${payload ? payload : ''}`);

export const GET_PERSONALITY_QUESTIONS = (payload) =>
  axios.get(`${urlconfig.surveyapi}/${VERSION}/personality-questions/${payload ? payload : ''}`);

export const GET_PREVIOUS_RESPONSE = (payload) =>
  axios.get(`${urlconfig.surveyapi}/${VERSION}/previous-response/${payload ? payload : ''}`);


export const POST_ANSWER = (answers) =>
  axios.post(`${urlconfig.surveyapi}/${VERSION}/survey-response/`, answers);

export const START_PERSONALITY_ANSWER = (payload) => {
  return axios.post(
    `${urlconfig.surveyapi}/${VERSION}/personality-updates/`,
    payload);
}
export const UPDATE_PERSONALITY_ANSWER = (token, payload) => {
  return axios.patch(
    `${urlconfig.surveyapi}/${VERSION}/personality-updates/${token}/`,
    payload);
}

export const GET_PERSONALITY_ANSWER = (payload) =>
  axios.get(`${urlconfig.surveyapi}/${VERSION}/personality-updates/${payload ? `${payload}` : ''}`);

export const GET_CULTURE = () => {
  return;
};

export const GET_MOODS = () => {
  return;
};

export const GET_BRIEF_SURVEY = (payload) =>
  axios.get(`${urlconfig.surveyapi}/${VERSION}/brief-token/${payload ? payload : ''}`);

export const UPDATE_BRIEF_RESPONSE = (payload) => {
  return axios.patch(`${urlconfig.surveyapi}/${VERSION}/brief-token/${payload?.id}/${payload?.query ? payload?.query : ''}`, payload);
}

export const POST_BRIEF_RESPONSE = (answers) =>
  axios.post(`${urlconfig.surveyapi}/${VERSION}/brief-response/`, answers);

export const GET_BRIEF_MODULAR_QUESTIONS = (payload) =>
  axios.get(`${urlconfig.surveyapi}/${VERSION}/brief-component-question/${payload ? payload : ''}`);

export const GET_CHAT_GPT_SUMMARY = (payload) => {
  return axios.post(
    `${urlconfig.surveyapi}/${VERSION}/chat-gpt-summary/`,
    payload);
}

export const GET_CHAT_GPT_SUMMARY_MULTI = (payload) => {
  return axios.post(
    `${urlconfig.surveyapi}/${VERSION}/chat-gpt-summary-multi/`,
    payload);
}

export const POST_MARKETING_ROI = (marketingRoi) =>
  axios.post(`${urlconfig.surveyapi}/${VERSION}/marketing-response/`, marketingRoi);
