import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { Loader } from "semantic-ui-react";
import Typewriter from "typewriter-effect";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_CHAT_GPT_SUMMARY } from "constants/actions";
import Logo from "assets/images/Ilogo.svg";

import { v4 as uuidv4 } from "uuid";

import SkipModal from "../Components/SkipModal";
import { followupAiTypeQuestion } from "constants/questionTypes";
const NewValidationToken = uuidv4();

const Follow_up = ({
  currentQuestionDet,
  saveUpdatedAnswers,
  language,
  refQues,
  handleNext,
}) => {
  const dispatch = useDispatch();
  const timeout = useRef(null);
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState("");
  const textareaRef = useRef();
  const [feedbackText, setFeedbackText] = useState("");
  const [open, setOpen] = useState(false);

  // console.log("currentQuestionDet", currentQuestionDet);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight - 16
      }px`;
    }
  }, []);

  const handleInput = (e) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight - 16}px`;
  };

  const handleChange = (event) => {
    setFeedbackText(event.target.value);
    const ResponseObj = {
      survey_q_id: currentQuestionDet.id,
      feedback: event.target.value,
      question_type: currentQuestionDet?.question_type,
      org_ref_ques_id: refQues?.response?.survey_q_id,
      dimension: refQues?.response?.dimension,
      factor: refQues?.response?.factor,
      fr: refQues?.response?.fr,
      id: refQues?.response?.id,
      q: refQues?.response?.q,
      ques_order: currentQuestionDet?.ques_order,
      org_ques_order: refQues?.response?.ques_order,
      question: refQues?.response?.question,
      response: refQues?.response?.response,
      reverse: refQues?.response?.reverse,
      role: refQues?.response?.role,
      type: refQues?.response?.type,
      prompt: prompt,
      clicked: refQues?.response?.clicked,
      prompt_question: question,
      prompt_feedback: refQues?.response?.feedback,
    };

    saveUpdatedAnswers(
      currentQuestionDet?.id,
      ResponseObj,
      currentQuestionDet.quesType
    );
  };

  const { get_chat_gpt } = useSelector(
    (state) => ({
      get_chat_gpt: state.chatgpt,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (
      !currentQuestionDet?.response?.feedback ||
      currentQuestionDet?.response?.prompt_feedback !==
        refQues?.response?.feedback
    ) {
      SubmitChatGpt();
    } else {
      console.log("run this");
      setQuestion(currentQuestionDet?.response?.prompt_question);
      setFeedbackText(currentQuestionDet?.response?.feedback);
    }
  }, []);

  useEffect(() => {
    if (get_chat_gpt?.response_pending === true) {
      timeout.current = setTimeout(() => {
        setLoading(false);
        // save()
      }, 15000);
    }

    if (get_chat_gpt?.response_pending === false) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    setLoading(get_chat_gpt?.response_pending);
    if (get_chat_gpt.chat_gpt_summary?.response) {
      setQuestion(get_chat_gpt.chat_gpt_summary?.response);
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [get_chat_gpt]);

  function SubmitChatGpt() {
    let _prompt = `Context:
    The following is feedback from a culture analysis survey. The feedback is a response to the following statement: ${refQues?.response?.question} \n
    Feedback:\n${refQues?.response?.feedback}\n
    Question:\nWhat follow up question would a world class culture consultant ask based on the feedback given that would help to get more context around this area, and also help to generate recommendations for this organization? Make sure your response is simple enough for an 7th grader to understand. Only return the question istelf. \n
    Response:\n`;
    // console.log(_prompt)
    setPrompt(_prompt);
    dispatch({
      type: SG_GET_CHAT_GPT_SUMMARY,
      payload: {
        payload: _prompt,
        token: NewValidationToken,
      },
    });
  }

  const handleSkip = () => {
    setOpen(true);
  };

  function SkipQuestion() {
    const ResponseObj = {
      survey_q_id: currentQuestionDet.id,
      feedback: "",
      dimension: refQues?.response?.dimension,
      factor: refQues?.response?.factor,
      fr: refQues?.response?.fr,
      id: refQues?.response?.id,
      q: refQues?.response?.q,
      ques_order: refQues?.response?.ques_order,
      question: refQues?.response?.question,
      response: refQues?.response?.feedback,
      reverse: refQues?.response?.reverse,
      role: refQues?.response?.role,
      type: refQues?.response?.type,
      prompt: "",
      prompt_question: "",
    };
    saveUpdatedAnswers(
      currentQuestionDet.id,
      ResponseObj,
      followupAiTypeQuestion
    );
    setOpen(false);
    handleNext();
  }

  return (
    <FeedbackContainer>
      <Title>
        Follow up
      </Title>
      <Description>
        {loading ? (
          <Loader active inline />
        ) : (
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .changeDelay(14)
                .typeString(question)
                .pauseFor(100)
                .start();
            }}
          />
        )}
      </Description>

      <StyledTextarea
        id={`txt_${currentQuestionDet?.id}`}
        value={feedbackText}
        onChange={handleChange}
        placeholder="Type your answer here"
        ref={textareaRef}
        onInput={handleInput}
      />

      <Skip onClick={handleSkip}>Skip &gt;</Skip>
      <SkipModal open={open} setOpen={setOpen} SkipQuestion={SkipQuestion} />
    </FeedbackContainer>
  );
};

export default Follow_up;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px;
  width: 100%;
  font-family: "Raleway", sans-serif;
`;

const Description = styled.div`
  color: #2a3039;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 34px;
  line-height: 1.6;
`;
const Title = styled.div`
  color: #2d70e2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 40px;
  display: flex;
`;

const StyledTextarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #2a3039;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
  font-family: "Raleway", sans-serif;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #2d70e2;
    outline: 0;
  }
`;

const Skip = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #2d70e2;
  margin-top: 40px;
  cursor: pointer;
`;

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #2d70e2;
  margin-right: 10px;
  min-width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

const IMG = styled.img`
  width: 20px;
`;
