export const serverApi = 'https://api.nulogic.co'

// export const currentApi = serverApi
export const currentApi = serverApi
// export const currentApi = localApi

export const Environment = 'Production'

export const SentryDSN_STG = "https://7e72a0cff941bbcefa80509a510bf741@o4507131261353984.ingest.us.sentry.io/4507136780533760";
export const SentryDSN_PROD =
  "https://7e72a0cff941bbcefa80509a510bf741@o4507131261353984.ingest.us.sentry.io/4507136780533760";

export const config = {
    surveyapi: `${currentApi}/api/survey`,
    appapi: `${currentApi}/api/organization`,
};

export default config

